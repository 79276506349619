* {
    margin: 0;
    padding: 0;
}

ul {
    list-style-type: none;
}

a {
    text-decoration: none;
    color: black;
}

a:hover {
    color: brown;
}